import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import language_config from "../../../language-config"

import logoPamPanorama from "../../static/logos/logo-pam-panorama.png"
import logoPamLocal from "../../static/logos/logo-local.png"
import logoIns from "../../static/logos/logo-ins.png"
import logoFranchising from "../../static/logos/logo-franchising.png"
import logoCity from "../../static/logos/logo-city.png"

import "./style.scss"

const Footer = ({ pageContext }) => {
    let [path, setPath] = useState("/")

    useEffect(() => {
        let newPath = ""
        if (typeof window !== "undefined") {
            newPath =
                (language_config[window.localStorage.language].is_default
                    ? ""
                    : "/" + window.localStorage.language) + "/"
        } else {
            newPath = "/"
        }
        setPath(newPath)
    }, [])

    return (
        <footer id="main-footer">
            <div className="container">
                <div className="pre-footer">
                    <div className="pre-footer-text ie">
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "footer.preFooter.text"
                                    ],
                            }}
                        ></p>
                    </div>

                    <div className="pre-footer-icons ie">
                        <Link
                            to={`${path}pam-panorama`}
                            style={{
                                backgroundImage: `url(${logoPamPanorama})`,
                            }}
                            aria-label="logo pam panorama"
                        ></Link>
                        <Link
                            to={`${path}pam-local`}
                            style={{ backgroundImage: `url(${logoPamLocal})` }}
                            aria-label="logo pam local"
                        ></Link>
                        <Link
                            to={`${path}pam-ins`}
                            style={{ backgroundImage: `url(${logoIns})` }}
                            aria-label="logo pam ins"
                        ></Link>
                        <Link
                            to={`${path}pam-franchising`}
                            style={{
                                backgroundImage: `url(${logoFranchising})`,
                            }}
                            aria-label="logo pam franchising"
                        ></Link>
                        <Link
                            to={`${path}pam-city`}
                            style={{ backgroundImage: `url(${logoCity})` }}
                            aria-label="logo pam city"
                        ></Link>
                    </div>
                </div>
            </div>
            <div className="end-footer">
                <div className="container">
                    <div className="ie">
                        <p className="pBig">
                            {
                                pageContext.localeResources[
                                    "footer.positions.title"
                                ]
                            }
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "gruppo" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.group"
                                    ]
                                }
                            </Link>
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "pam-panorama" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.pamPanorama"
                                    ]
                                }
                            </Link>
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "local" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.pamLocal"
                                    ]
                                }
                            </Link>
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "ins" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.pamDiscount"
                                    ]
                                }
                            </Link>
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "franchising" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.pamFranchising"
                                    ]
                                }
                            </Link>
                        </p>
                        <p>
                            <Link
                                to={`${path}lavora-con-noi`}
                                state={{ insegna: "city" }}
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.positions.pamCity"
                                    ]
                                }
                            </Link>
                        </p>
                    </div>
                    <div className="ie">
                        <p className="pBig">
                            {pageContext.localeResources["footer.press.title"]}
                        </p>
                        <p>
                            <Link to={`${path}press`}>
                                {
                                    pageContext.localeResources[
                                        "footer.press.kit"
                                    ]
                                }
                            </Link>
                        </p>
                        {/* <p><Link to={`${path}press-comunicati`}>{pageContext.localeResources['footer.press.comunicati']}</Link></p> */}
                        <p>
                            <Link to={`${path}press-media`}>
                                {
                                    pageContext.localeResources[
                                        "footer.press.media"
                                    ]
                                }
                            </Link>
                        </p>
                    </div>
                    <div className="ie">
                        <p className="pBig">
                            {
                                pageContext.localeResources[
                                    "footer.contacts.title"
                                ]
                            }
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "footer.contacts.address"
                                    ],
                            }}
                        ></p>
                        <p>
                            <a
                                href="tel:0415495111"
                                aria-label={
                                    pageContext.localeResources[
                                        "footer.contacts.telLabel"
                                    ]
                                }
                            >
                                {
                                    pageContext.localeResources[
                                        "footer.contacts.tel"
                                    ]
                                }
                            </a>
                        </p>
                    </div>
                </div>
                <p>
                    <Link
                        to="modello-organizzativo-codice-etico"
                        aria-label="vai al modello organizzativo"
                    >
                        {
                            pageContext.localeResources[
                                "footer.docs.codiceEtico_modelloOrganizzativo"
                            ]
                        }
                    </Link>
                    &nbsp; | &nbsp;
                    <a
                        href="https://www.pampanorama.it/privacy-legal/garanzia-legale"
                        target="_blank"
                        aria-label="vai alla garanzia legale"
                    >
                        {
                            pageContext.localeResources[
                                "footer.docs.garanziaLegale"
                            ]
                        }
                    </a>
                    &nbsp; | &nbsp;
                    <Link
                        to={`${path}privacy`}
                        aria-label="vai alla privacy policy"
                    >
                        {
                            pageContext.localeResources[
                                "footer.docs.privacyPolicy"
                            ]
                        }
                    </Link>
                    &nbsp; | &nbsp;
                    <Link
                        to="/whistleblowing"
                        aria-label="vai al whistleblowing"
                    >
                        Whistleblowing
                    </Link>
                </p>
            </div>
        </footer>
    )
}

export default Footer
