module.exports = {
    it: {
        is_default: true,
        path: 'it',
        display_name: 'It',
    },
    en: {
        path: 'en',
        display_name: 'En',
    },
}