import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import language_config from "../../../language-config";

import logoGruppoPam from '../../static/logos/logo-gruppo-home.png';

import "./style.scss";

const Menu = ({ pageContext }) => {
    const languages = Object.keys(language_config);
    let [selectedLanguage, setSelectedLanguage] = useState(pageContext.locale);
    let [path, setPath] = useState(``);
    let [menuIsClose, toggleMenuClose] = useState(true);

    const changeLanguage = (lang) => {
        window.localStorage.setItem('language', lang);
        navigate('/' + lang)
    }

    useEffect(() => {
        setSelectedLanguage(pageContext.locale)
    }, [pageContext.locale]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setPath((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        }
    }, [selectedLanguage]);


    return (
        <header>
            <nav className={`main-header ${menuIsClose ? 'close' : 'open'}`}>
                <h1 style={{display: "none"}}>Gruppo Pam spa</h1>
                <div className='container'>
                    <Link to={`${path}`} className="menu-logo" onClick={() => toggleMenuClose(true)}>
                        <img alt="Logo Gruppo Pam" src={logoGruppoPam} />
                    </Link>

                    <div className='mobile-menu'>
                        <button className='mobile-hamb' onClick={() => toggleMenuClose(!menuIsClose)} alt={menuIsClose ? "apri il menu" : "chiudi il menu"} aria-label={menuIsClose ? "apri il menu" : "chiudi il menu"}></button>
                    </div>

                    <ul className="main-menu">
                        <li><Link to={`${path}chi-siamo/`}>{pageContext.localeResources['menu.chiSiamo']}</Link></li>
                        <li className="ie">
                            <span>{pageContext.localeResources['menu.insegne']}</span>
                            <ul className="dropdown-insegne">
                                <li><Link to={`${path}pam-panorama`}>PAM Panorama</Link></li>
                                <li><Link to={`${path}pam-local`}>PAM Local</Link></li>
                                <li><Link to={`${path}pam-city`}>PAM City</Link></li>
                                <li><Link to={`${path}pam-ins`}>IN's</Link></li>
                                <li><Link to={`${path}pam-franchising`}>PAM Franchising</Link></li>
                                <li><Link to={`${path}pam-a-casa`}>Pam a casa</Link></li>
                            </ul>
                        </li>
                        <li><Link to={`${path}news`}>{pageContext.localeResources['menu.news']}</Link></li>
                        
                        <li><Link to={`${path}press`}>{pageContext.localeResources['menu.press']}</Link></li>
                        <li><Link to={`${path}contatti`}>{pageContext.localeResources['menu.contatti']}</Link></li>
                        
                        <li><select className="langSelect" value={selectedLanguage} onChange={e => changeLanguage(e.target.value)}>
                            {languages.map(lang => (
                                <option key={lang} value={lang}>
                                    {language_config[lang].display_name}
                                </option>
                            ))}
                        </select></li>
                        
                        <li><a className="btn careersBtn" href="https://lavoraconnoi.gruppopam.it/">{pageContext.localeResources['menu.careers']}</a></li>
                        {/* <li><Link className="btn careersBtn" to={`${path}/lavora-con-noi`}>{pageContext.localeResources['menu.careers']}</Link></li> */}
                        <li><a className="btn fornitoriBtn" href="https://www.pampanorama.it/portale-fornitori" dangerouslySetInnerHTML={{ __html: pageContext.localeResources['menu.portal']}}></a></li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Menu
