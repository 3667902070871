import React, { useEffect, useState } from "react";
import AOS from 'aos';
import Menu from "../Menu";
import Footer from "../Footer";

import "./style.scss";
import "../../utils/general.scss";
import 'aos/dist/aos.css';

const Layout = ({ children, pageContext }) => {
    let [locale, setLocale] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.location.pathname.indexOf('/en/') === 0) {
                if (window.localStorage.language !== 'en') {
                    window.localStorage.setItem('language', 'en');
                }
                setLocale('en');
            }
            else {
                if (window.localStorage.language !== 'it') {
                    window.localStorage.setItem('language', 'it');
                }
                setLocale('it');
            }

        }
    }, []);
    
    useEffect(() => {
        AOS.init({
            once: true
        });
        return;
    }, []);
    
    useEffect(() => {
        if (navigator.userAgent.indexOf('Trident') !== -1 || navigator.userAgent.indexOf('MSIE') !== -1) {

            // edge
            // "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.92 Safari/537.36 Edg/81.0.416.53"

            // ie
            // "Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"

            var elements = document.querySelectorAll('.ie');
        
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.marginLeft = "auto";
                elements[i].style.marginRight = "auto";
            }

            // if (document.querySelector('.videoBanner h1')) {
            //     let videoBannerH = document.querySelector('.videoBanner h1');
            //     videoBannerH.style.fontSize = "2.5vw";
            //     videoBannerH.style.top = "20px";
            // }

            if (document.querySelector('.videoBanner video')) {
                let videoBanner = document.querySelector('.videoBanner video');
                videoBanner.style.position = "absolute";
                videoBanner.style.minWidth = "100%";
                videoBanner.style.minHeight = "100%";
                videoBanner.style.maxWidth = "125vw";
                videoBanner.style.maxHeight = "100vw";
                videoBanner.style.width = "auto";
                videoBanner.style.height = "auto";
                videoBanner.style.left = "50%";
                videoBanner.style.top = "50%";
                videoBanner.style.transform = "translate(-50%, -50%)";
            }
        }
        return;
    }, []);

    return (
        <>
            <Menu pageContext={{...pageContext, locale}} locale={locale}/>
            <section id="main-content" data-locale={locale}>{children}</section>
            <Footer pageContext={{...pageContext, locale}} locale={locale}/>
        </>
    )
}

export default Layout
